import * as React from "react";
import { File, FileReadOptions, Run, fetchApi } from "~/dataLayer";
import { SelectFile } from "@konbert/ui/SelectFile";
import {
  Format,
  READ_FORMATS,
  formatHasReadOptions,
  getFormatById,
} from "@konbert/ui/format";
import { FormatForm, getFormatDefaultOptions } from "@konbert/ui/FormatForm";
import { humanFileSize } from "@konbert/ui/utils";
import { Action, State, reducer } from "~/lib/state";

import { createDataLayer } from "~/config";
import { Link, useNavigate } from "@remix-run/react";
import { ArrowRightIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { formatIconsComponents } from "~/components/FormatIcons";
import type { ConvertFileArgs } from "~/routes/api.v2.files.$id.convert";
// import { SelectNotion } from "./SelectNotion";
// import { SelectGoogleSheet } from "./SelectGoogleSheet";

const dataLayer = createDataLayer();

const DATA_SAMPLES = [
  {
    url: "https://tablepad.io/samples/customer_orders.csv",
    title: "fake_customer_orders.csv",
    format: "csv",
  },
  {
    url: "https://tablepad.io/samples/pokemons.csv",
    title: "pokemons.csv",
    format: "csv",
  },
  {
    url: "https://tablepad.io/samples/fdic_bank_failures.csv",
    title: "FDIC_bank_failures.csv",
    format: "csv",
  },
];

const DATA_SOURCES = [
  ...READ_FORMATS.filter((format) => !("extends" in format)).map((format) => ({
    id: format.id,
    title: format.title,
    icon: formatIconsComponents[format.id] ?? formatIconsComponents["csv"],
    isIntegration: false,
  })),
];

export async function authorizeGoogleSheets(): Promise<{ ok: boolean }> {
  const clientId =
    "662854496600-6rjjad04mepn2odefj5i6gniu7shkul7.apps.googleusercontent.com";
  const scope = "https://www.googleapis.com/auth/spreadsheets.readonly";

  return new Promise((resolve, reject) => {
    // @ts-expect-error - Google API client is not typed
    const tokenClient = google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope: scope,
      redirect_uri: "postmessage",
      callback: (response: { code: string | undefined }) => {
        if (response.code) {
          const body = new FormData();
          body.append("code", response.code);
          // Send the authorization code to the server
          resolve(
            fetch("/auth/google-sheets", {
              method: "POST",
              body: body,
            }).then((res) => res.json())
          );
        } else {
          reject(new Error("No authorization code"));
        }
      },
    });

    tokenClient.requestCode();
  });
}

export async function authorizeNotion(): Promise<{ error: string | null }> {
  const notionClientId = "57d7cba7-c361-4a32-9f00-d5a315bed36d";
  const redirectUri = encodeURIComponent(
    `${window.location.origin}/auth/notion/callback`
  );
  const notionAuthUrl = `https://api.notion.com/v1/oauth/authorize?client_id=${notionClientId}&response_type=code&owner=user&redirect_uri=${redirectUri}`;

  const popup = window.open(notionAuthUrl, "_blank", "width=500,height=600");

  if (popup === null) {
    return { error: "Failed to open authentication pop up" };
  }

  return new Promise((resolve) => {
    function handleMessage(event: MessageEvent) {
      console.log("message", event);
      if (
        event.origin === window.location.origin &&
        event.data.type === "notion-auth"
      ) {
        window.removeEventListener("message", handleMessage);
        popup?.close();
        if (event.data.success) {
          resolve({ error: null });
        } else {
          resolve({ error: `Authorization failed: ${event.data.message}` });
        }
      }
    }

    window.addEventListener("message", handleMessage);
  });
}

function SelectFileSource({
  state,
  dispatch,
  allowBack,
  navigateToFileViewer,
}: {
  state: Extract<State, { type: "selectingFile" }>;
  allowBack: boolean;
  dispatch: React.Dispatch<Action>;
  navigateToFileViewer: (fileId: string) => void;
}) {
  const format = getFormatById(state.formatId);

  const [formatFormIsDirty, setFormatFormIsDirty] =
    React.useState<boolean>(false);
  const [formatFormIsValid, setFormatFormIsValid] =
    React.useState<boolean>(true);

  if (!format) {
    return null;
  }

  async function createFileAndView() {
    async function redirectToDataViewer(file: File) {
      const convertResponse = await fetchApi<Run>({
        method: "POST",
        url: "/api/v2/files/" + file.file_id + "/convert",
        body: {
          name: file.name,
          inputOptions: {
            ...readOptions,
          },
        } satisfies ConvertFileArgs,
      });

      if (convertResponse.error !== null) {
        dispatch({
          type: "creatingFile/error",
          error: convertResponse.error,
        });
        return;
      }

      navigateToFileViewer(file.file_id);
    }

    if (state.type !== "selectingFile" || state.fileSource === null) {
      dispatch({
        type: "selectingFile/error",
        error: {
          type: "choose_data",
          message: "Please choose a data source",
        },
      });
      return;
    }

    if (formatFormIsValid === false) {
      setFormatFormIsDirty(true);
      return;
    }

    const format = getFormatById(state.formatId);

    if (format === null) {
      throw new Error("Unknown format");
    }

    const readOptions: FileReadOptions = {
      ...getFormatDefaultOptions(format, "read"),
      ...state.readOptions,
      format_id: format.extends ?? format.id,
    };

    dispatch({
      type: "selectingFile/createFile",
      fileSource: state.fileSource,
      readOptions: readOptions,
    });
    switch (state.fileSource.type) {
      case "file": {
        const response = await dataLayer.createFile({
          name: state.fileSource.file.name,
          size: state.fileSource.file.size,
          metadata: {},
          read_options: readOptions,
        });

        if (response.error !== null) {
          dispatch({
            type: "creatingFile/error",
            error: response.error,
          });
          return;
        }

        const domFile = state.fileSource.file;

        dispatch({
          type: "creatingFile/startUpload",
          domFile: domFile,
          file: response.data,
        });

        const { promise } = dataLayer.uploadFile({
          fileId: response.data.file_id,
          file: domFile,
          notifyProgress: (bytes: number) => {
            dispatch({
              type: "creatingFile/uploading/progress",
              uploadedByteLength: bytes,
            });
          },
        });

        await promise;

        redirectToDataViewer(response.data);
        break;
      }

      case "url": {
        const baseName = state.fileSource.url.split("/").pop();

        const response = await dataLayer.createFile({
          name: baseName,
          url: state.fileSource.url,
          read_options: readOptions,
        });

        if (response.error !== null) {
          dispatch({
            type: "creatingFile/error",
            error: response.error,
          });
          return;
        }

        redirectToDataViewer(response.data);
        break;
      }

      case "rawText": {
        const response = await dataLayer.createFile({
          name: "Untitled",
          data: state.fileSource.text,
          read_options: readOptions,
        });

        if (response.error !== null) {
          dispatch({
            type: "creatingFile/error",
            error: response.error,
          });
          return;
        }

        redirectToDataViewer(response.data);
        break;
      }
    }
  }

  return (
    <>
      {allowBack && (
        <div className="border-b border-gray-100 px-4 py-1 w-full flex items-center justify-between">
          <button
            className="btn px-0 flex items-center text-gray-400 hover:text-gray-800"
            onClick={() => dispatch({ type: "selectDataSource" })}
          >
            <ChevronLeftIcon className="w-4 mr-0.5" />
            <span>Back</span>
          </button>
          <div className="text-gray-700 font-semibold">{format.title}</div>
          <div className="w-16"></div>
        </div>
      )}
      <div className="w-full flex flex-col-reverse lg:flex-row-reverse">
        {formatHasReadOptions(format) && (
          <div className="p-4 pb-8 lg:w-[240px] flex flex-col gap-y-4 shrink-0">
            <FormatForm
              format={format}
              isDirty={formatFormIsDirty}
              type="read"
              values={state.readOptions}
              onChange={(readOptions) => {
                dispatch({
                  type: "selectingFile/changeReadOptions",
                  readOptions,
                });
              }}
              onValidate={setFormatFormIsValid}
            />
          </div>
        )}
        <div className="p-4 flex-grow">
          {state.error && state.error.type === "needs_upgrade" && (
            <div className="mx-auto mb-4 overflow-auto py-3 px-4 rounded-xl bg-amber-50">
              <div className="flex gap-x-2 items-center">
                <div className="">
                  <p className="text-amber-600">{state.error.message}</p>
                </div>
                <div className="ml-auto shrink-0">
                  <Link
                    to="/pricing"
                    className="text-sm btn px-4 py-2 rounded-lg bg-amber-500 hover:bg-amber-600 text-white shrink-0"
                  >
                    <span className="icon w-4 mr-1 align-[-2px] text-white/40">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M8.498 20h7.004A6.523 6.523 0 0 1 12 23.502 6.523 6.523 0 0 1 8.498 20zM18 14.805l2 2.268V19H4v-1.927l2-2.268V9c0-3.483 2.504-6.447 6-7.545C15.496 2.553 18 5.517 18 9v5.805zM12 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                      </svg>
                    </span>{" "}
                    <span>Upgrade</span>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {state.error && state.error.type !== "needs_upgrade" && (
            <div className="alert alert-danger mb-4">{state.error.message}</div>
          )}

          <SelectFile
            onChange={(fileSource) => {
              dispatch({
                type: "selectingFile/changeSource",
                fileSource,
              });

              // if (format === null) {
              //   const format = formatFromFileSource(fileSource);
              //
              //   if (format && format.readOptions) {
              //     setFormat(format);
              //   }
              // }
            }}
            selectedSource={state.fileSource}
            enabledSources={{
              rawText: format?.isPlainText ?? true,
              fileUpload: true,
              url: true,
            }}
          />
        </div>
      </div>
      <div className="p-4 border-t border-gray-100 pt-4 flex items-center justify-center mt-auto w-full gap-x-4">
        <button
          className="btn btn-primary flex items-center px-8 group"
          id="open-file-button"
          onClick={() => createFileAndView()}
        >
          <span>Explore data</span>
          <ArrowRightIcon className="w-4 h-4 ml-2 mt-0.5 group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    </>
  );
}

export function DataViewer(props: {
  initialQuery?: string;
  formatId?: string;
}): JSX.Element {
  let initialFormat: Format | null = null;
  const navigate = useNavigate();

  if (props.formatId != null) {
    initialFormat = getFormatById(props.formatId);

    if (initialFormat === null) {
      throw new Error(`Unknown format: ${props.formatId}`);
    }
  }

  function navigateToFileViewer(fileId: string) {
    if (props.initialQuery) {
      navigate(`/files/${fileId}?q=${encodeURIComponent(props.initialQuery)}`);
    } else {
      navigate(`/files/${fileId}`);
    }
  }

  const initialState: State =
    initialFormat === null
      ? {
          type: "selectingDataSource",
        }
      : {
          type: "selectingFile",
          formatId: initialFormat.id,
          fileSource: null,
          readOptions: {},
          error: null,
        };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <div className="w-full max-w-screen-md">
      <div className="w-full mx-auto bg-white rounded-xl shadow-sm">
        {state.type === "creatingFile" && (
          <div>
            <div className="py-16 px-4 flex flex-col items-center justify-center">
              <span className="spinner-icon spinner w-6 h-6 block mb-4"></span>
              <span>Creating data source</span>
            </div>
          </div>
        )}

        {state.type === "creatingFile/loading" && (
          <div>
            <div className="py-16 px-4 flex flex-col items-center justify-center">
              <span className="spinner-icon spinner w-6 h-6 block mb-4"></span>
              <span>Loading data source</span>
            </div>
          </div>
        )}

        {state.type === "creatingFile/uploading" && (
          <div className="py-16 px-4 flex flex-col items-center justify-center">
            <span className="spinner-icon spinner w-6 h-6 block mb-4"></span>
            <span>Uploading {state.domFile.name}</span>
            <div className="block pt-4">
              {humanFileSize(state.uploadedByteLength)} /{" "}
              {humanFileSize(state.domFile.size)}
            </div>
          </div>
        )}

        {state.type === "selectingDataSource" && (
          <>
            <div className="py-8 px-8 flex flex-col items-center w-full">
              <div className="text-gray-400 w-full ml-2 pb-2 text-sm">
                Choose a data source to get started.
              </div>
              <div className="grid gap-4 mt-4 flex-wrap grid-cols-2 md:grid-cols-4 w-full">
                {DATA_SOURCES.map((source) => (
                  <button
                    key={source.id}
                    onClick={() => {
                      if (source.isIntegration) {
                        dispatch({
                          type: "selectingDataSource/selectIntegration",
                          formatId: source.id,
                        });
                      } else {
                        dispatch({
                          type: "selectingDataSource/selectFileSource",
                          formatId: source.id,
                          fileSource: null,
                          readOptions: {},
                        });
                      }
                    }}
                    className="rounded-xl bg-gray-50 flex flex-col items-center justify-center p-4 hover:bg-gray-100 transition-colors"
                  >
                    <source.icon className="w-8 h-8 mb-3" />
                    <span className="text-sm">{source.title}</span>
                  </button>
                ))}
              </div>
            </div>
            <div className="py-8 border-t border-gray-100 w-full px-8">
              <p className="mb-6 text-gray-400 ml-2 text-sm">
                Don&apos;t have data handy? Try some sample data!
              </p>
              <div className="flex gap-2 flex-wrap">
                {DATA_SAMPLES.map((sample) => (
                  <button
                    key={sample.url}
                    className="btn flex items-center px-3 py-1 border-gray-200 hover:border-gray-300 text-sm rounded-full text-gray-600"
                    title="Load sample data"
                    onClick={() => {
                      dispatch({
                        type: "selectingDataSource/selectFileSource",
                        formatId: sample.format,
                        readOptions: {},
                        fileSource: {
                          type: "url",
                          url: sample.url,
                        },
                      });
                      setTimeout(() => {
                        document.getElementById("open-file-button")?.click();
                      }, 100);
                    }}
                  >
                    <span>{sample.title}</span>
                    <ArrowUpRightIcon className="w-3 h-3 ml-1 mt-0.5 text-gray-300" />
                  </button>
                ))}
              </div>
            </div>
          </>
        )}

        {state.type === "selectingFile" && (
          <SelectFileSource
            state={state}
            allowBack={props.formatId === undefined}
            dispatch={dispatch}
            navigateToFileViewer={navigateToFileViewer}
          />
        )}
      </div>
    </div>
  );
}
