import type { MetaFunction } from "@remix-run/node";
import { DataViewer } from "~/components/DataViewer";
import Layout from "~/components/Layout";
import { READ_FORMATS } from "@konbert/ui/format";
import { Link } from "@remix-run/react";
import { formatIconsComponents } from "~/components/FormatIcons";
import React from "react";
import classNames from "classnames";
import FileConvertersGrid from "~/components/ConvertersGrid";

export const meta: MetaFunction = () => {
  return [
    { title: "Simple and Reliable Data Tools for Everyone" },
    {
      name: "description",
      content:
        "We build easy-to-use tools to help you convert, clean, analyze, and visualize your data.",
    },
    {
      name: "keywords",
      content:
        "convert, ai, analysis, analytics, data, csv, json, arrow, parquet, google sheets",
    },
  ];
};

export default function Index() {
  return (
    <Layout className="bg-gray-50">
      <div className="bg-slate-50">
        <div className="relative isolate px-6 lg:px-8">
          <div className="mx-auto py-16 md:py-24 lg:py-32">
            <div className="text-center">
              <h1 className="text-4xl font-display font-bold tracking-tight text-primary-700 sm:text-5xl max-w-2xl mx-auto">
                Data Made Simple
              </h1>
              <p className="mt-6 text-lg leading-6 text-gray-600 max-w-md mx-auto">
                We build easy-to-use tools to help you convert, clean, analyze,
                and visualize your data.
              </p>
            </div>
          </div>
        </div>
        <div className="pb-16">
          <div className="max-w-screen-md mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
            <Link
              to="/convert"
              className="bg-white rounded-xl p-8 shadow-sm block border border-transparent hover:border-gray-200 transition-colors"
            >
              <h2 className="text-xl font-bold font-display tracking-tight text-gray-900 pb-2">
                Data conversion
              </h2>
              <p className="text-md text-gray-500">
                Convert your data files between 15+ supported formats.
              </p>
            </Link>
            <Link
              to="/viewer"
              className="bg-white rounded-xl p-8 shadow-sm block border border-transparent hover:border-gray-200 transition-colors"
            >
              <h2 className="text-xl font-bold font-display tracking-tight text-gray-900 pb-2">
                Data viewer
              </h2>
              <p className="text-md text-gray-500">
                View your data in an easy to use interactive table.
              </p>
            </Link>
            <Link
              to="/graph-maker"
              className="bg-white rounded-xl p-8 shadow-sm block border border-transparent hover:border-gray-200 transition-colors"
            >
              <h2 className="text-xl font-bold font-display tracking-tight text-gray-900 pb-2">
                Graph Maker
              </h2>
              <p className="text-md text-gray-500">
                Create graphs and charts from your data using AI.
              </p>
            </Link>
          </div>
        </div>

        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-primary-50 mx-auto mb-6 text-primary-500">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M3.16113 4.46875C5.58508 2.0448 9.44716 1.9355 12.0008 4.14085C14.5528 1.9355 18.4149 2.0448 20.8388 4.46875C23.2584 6.88836 23.3716 10.741 21.1785 13.2947L13.4142 21.0858C12.6686 21.8313 11.4809 21.8652 10.6952 21.1874L10.5858 21.0858L2.82141 13.2947C0.628282 10.741 0.741522 6.88836 3.16113 4.46875ZM4.57534 5.88296C2.86819 7.59011 2.81942 10.3276 4.42902 12.0937L4.57534 12.2469L12 19.6715L17.3026 14.3675L13.7677 10.8327L12.7071 11.8934C11.5355 13.0649 9.636 13.0649 8.46443 11.8934C7.29286 10.7218 7.29286 8.8223 8.46443 7.65073L10.5656 5.54823C8.85292 4.17713 6.37076 4.23993 4.7286 5.73663L4.57534 5.88296ZM13.0606 8.71139C13.4511 8.32086 14.0843 8.32086 14.4748 8.71139L18.7168 12.9533L19.4246 12.2469C21.1819 10.4896 21.1819 7.64032 19.4246 5.88296C17.7174 4.17581 14.9799 4.12704 13.2139 5.73663L13.0606 5.88296L9.87864 9.06494C9.51601 9.42757 9.49011 9.99942 9.80094 10.3919L9.87864 10.4792C10.2413 10.8418 10.8131 10.8677 11.2056 10.5569L11.2929 10.4792L13.0606 8.71139Z"></path>
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl max-w-lg mx-auto font-display">
              +1,000,000
              <br /> files processed
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Since 2017, we've been trusted by all kinds of organizations and
              professionals, from data experts, big tech companies, governments
              to healthcare providers.
            </p>
          </div>
        </div>

        <section className="py-24">
          <FileConvertersGrid />
        </section>
      </div>
    </Layout>
  );
}

export const FileViewers = () => {
  return (
    <section className="mx-auto py-36 px-4 bg-white">
      <div className="space-y-20">
        <div className="text-center">
          <p className="text-2xl md:text-4xl font-display tracking-tight pb-4 text-gray-900">
            View Your Files
          </p>
          <p className="text-gray-600 max-w-lg mx-auto text-lg">
            We support a variety of file formats. Turn boring data files into
            engaging visuals ✨. Select a format below to start exploring your
            data.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-screen-lg mx-auto">
          {READ_FORMATS.map((format) => (
            <Link
              to={`/viewer/${format.id}`}
              key={format.id}
              className="flex bg-white dark:bg-gray-950 rounded-lg shadow-sm border border-gray-200 hover:border-gray-300  dark:border-gray-800 overflow-hidden py-2 px-2"
            >
              {React.createElement(
                formatIconsComponents[format.id] ??
                  formatIconsComponents["csv"],
                {
                  className: "mr-2.5 w-6 h-6",
                }
              )}
              {format.title} Viewer
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export function HeroWithDataForm({
  heading,
  headingClassName,
  subheading,
  dataViewerProps,
}: {
  heading: React.ReactNode;
  headingClassName?: string;
  subheading: React.ReactNode;
  dataViewerProps?: React.ComponentProps<typeof DataViewer>;
}) {
  return (
    <div className="hero-bg bg-gray-50 relative py-8 pb-28">
      <div className="max-w-screen-md mx-auto z-10 relative px-4">
        <div className="text-center pb-20 py-12">
          <h1
            className={classNames(
              "font-display font-bold tracking-tight text-4xl md:text-5xl mx-auto pb-2 text-black",
              headingClassName
            )}
          >
            {heading}
          </h1>
          <p className="mt-4 text-lg leading-6 text-gray-500 max-w-[650px] mx-auto">
            {subheading}
          </p>
        </div>
        <DataViewer {...dataViewerProps} />
      </div>
    </div>
  );
}

export function GraphsFeature() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto flex flex-col md:flex-row justify-center items-center gap-y-8 gap-x-20">
          <div className="">
            <div className="lg:max-w-lg">
              <h3 className="mt-2 text-3xl font-display tracking-tight text-gray-900 sm:text-3xl">
                Generate Stunning Charts
              </h3>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Uncover valuable insights by visualizing your data with stunning
                charts, by simply asking questions in plain English.
              </p>
            </div>
          </div>
          <video
            src="./create-graphs.mp4"
            className="w-full max-w-none sm:w-[38rem] md:-ml-4 lg:-ml-0"
            autoPlay
            playsInline
            muted
            loop
            preload="none"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}
